<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-start">
        <v-btn v-if="$can('create', 'dados de repasses')" class="mr-3" color="success" @click="dialog = true"
          >NOVO REPASSE</v-btn
        >
        <v-btn v-if="$can('create', 'dados de repasses')" color="warning" @click="showDialogExcel"
          >IMPORTAR VIA EXCEL</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="{ shorten: $vuetify.breakpoint.lgAndUp }">
        <v-data-table
          :headers="grid.headers"
          :items="filteredItems"
          :loading="grid.loading"
          loading-text="Carregando... aguarde"
          locale="pt"
          class="elevation-1"
        >
          <template v-slot:item.codigoMec="{ item }">
            <span>{{ item.codigoMec }}</span>
          </template>

          <template v-slot:item.cnpjEscola="{ item }">
            <span>{{ item.cnpjEscola }}</span>
          </template>

          <template v-slot:item.cnpjUex="{ item }">
            <span>{{ item.cnpjUex }}</span>
          </template>

          <template v-slot:item.nomeEscola="{ item }">
            <span>{{ item.nomeEscola }}</span>
          </template>

          <template v-slot:item.valorRepasse="{ item }">
            <span>{{ item.valorRepasse | toCurrency }}</span>
          </template>

          <template v-slot:item.custeio="{ item }">
            <span>{{ item.custeio | toCurrency }}</span>
          </template>

          <template v-slot:item.capital="{ item }">
            <span>{{ item.capital | toCurrency }}</span>
          </template>

          <template v-slot:item.ano="{ item }">
            <span>{{ item.ano }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div>
              <v-icon v-if="$can('edit', 'dados de repasses')" color="green" class="mr-3" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon v-if="$can('delete', 'dados de repasses')" color="red" @click="deletarRepasse(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>

        <v-navigation-drawer
          v-if="$vuetify.breakpoint.lgAndUp || mobileFilter"
          v-model="mobileFilter"
          :permanent="$vuetify.breakpoint.lgAndUp"
          :absolute="$vuetify.breakpoint.lgAndUp"
          :fixed="$vuetify.breakpoint.mdAndDown"
          right
          class="elevation-1"
        >
          <v-card elevation="0" class="pa-4">
            <v-card-title class="grey--text text--darken-2"
              >Filtros</v-card-title
            >
            <v-text-field
              v-model="grid.filters.nomeEscola"
              label="Nome escola"
              dense
              solo
            ></v-text-field>

            <v-text-field
              v-model="grid.filters.cnpjEscola"
              label="CNPJ escola"
              dense
              solo
            ></v-text-field>

            <v-text-field
              v-model="grid.filters.cnpjUex"
              label="CNPJ Uex"
              dense
              solo
            ></v-text-field>
            
            <v-text-field
              v-model="grid.filters.codigoMec"
              label="Codigo mec"
              dense
              solo
            ></v-text-field>

            <v-text-field
              v-model="grid.filters.ano"
              label="Ano do repasse"
              dense
              solo
            ></v-text-field>

            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" text @click="filter"> Filtrar </v-btn>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer>
      </v-col>
    </v-row>

    <!-- Dialog Import Via Arquivo-->
    <v-row justify="center">
      <v-dialog v-model="dialogExcel" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Importar via arquivo excel</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
                ref="form"
                v-model="isFormValid"
                autocomplete="off"
                enctype="multipart/form-data"
              >
                <v-row>
                  <p>
                      <strong>O arquivo excel precisa respeitar a tabela abaixo.</strong>
                      <ul>
                        <li>
                          <h2><strong>Célula C:</strong> Código mec</h2>
                        </li>
                        <li>
                          <h2><strong>Célula D:</strong> Nome Escola</h2>
                        </li>
                        <li>
                          <h2><strong>Célula F:</strong> Valor repasse</h2>
                        </li>
                        <li>
                          <h2><strong>Célula G:</strong> Custeio</h2>
                        </li>
                        <li>
                          <h2><strong>Célula H:</strong> Capital</h2>
                        </li>
                        <li>
                          <h2><strong>Célula I:</strong> Ano do Repasse</h2>
                        </li>
                        <li>
                          <h2><strong>Célula J:</strong> Cnpj Escola</h2>
                        </li>
                        <li>
                          <h2><strong>Célula L:</strong> Cnpj Uex</h2>
                        </li>
                      </ul>
                  </p>
                  <v-col cols="12">
                    <v-file-input
                      v-model="files"
                      counter
                      clearable
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      show-size
                      :disabled="isProcessing"
                      :loading="isProcessing"
                      truncate-length="15"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeExcel">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleItemExcel">
              Importar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialog-->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="isFormValid" autocomplete="off">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Nome Escola"
                      v-model="defaultRepasse.nomeEscola"
                      :rules="rules.nomeEscola"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Código Mec"
                      v-model="defaultRepasse.codigoMec"
                      :rules="rules.codigoMec"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="CNPJ Escola"
                      v-model="defaultRepasse.cnpjEscola"
                      :rules="rules.cnpjEscola"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="CNPJ Uex"
                      v-model="defaultRepasse.cnpjUex"
                      :rules="rules.cnpjUex"
                      required
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-currency-field 
                      label="Valor De Repasse"
                      v-model="defaultRepasse.valorRepasse"
                      required
                    ></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-currency-field 
                      label="Custeio"
                      v-model="defaultRepasse.custeio"
                      required
                    ></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-currency-field 
                      label="Capital"
                      v-model="defaultRepasse.capital"
                      required
                    ></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Ano do Repasse"
                      v-model="defaultRepasse.ano"
                      :rules="rules.ano"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="handleItem">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import configurations from "~/commons/configurations";
import notificationMixin from "mixins/notification.mixin";
import pageLoadingMixin from "mixins/page-loading.mixin";
import Validations from "services/validations/validations.js";
import { AUTH_NAMESPACE, USER, MENU } from "store/modules/auth";

const { mapGetters: postLoginGetters } = createNamespacedHelpers(
  AUTH_NAMESPACE
);

export default {
  name: "ManageRepasses",
  mixins: [pageLoadingMixin, notificationMixin],
  beforeMount() {
    this.showPageLoading();
    this.verificarMenuDoUsuario();
  },
  created() {
    this.getRepasses();
    this.hidePageLoading();
  },
  data: () => ({
    files: [],
    indexRepasse: -1,
    dialog: false,
    dialogExcel: false,
    isProcessing: false,
    isFormValid: undefined,
    isMobileFilterVisible: false,
    mobileFilter: null,
    editedIndex: -1,
    breadcrumbs: [
      { text: "Início", to: "/admin" },
      { text: "Repasses", to: "/admin/repasses" },
    ],
    grid: {
      loading: false,
      filtered: false,
      filters: {
        nomeEscola: "",
        cnpjEscola: "",
        codigoMec: "",
        cnpjUex: "",
        ano: "",
      },
      headers: [
        { text: "Id", value: "repasseId", align: " d-none" },
        { text: "Codigo Mec", value: "codigoMec" },
        { text: "Cnpj Escola", value: "cnpjEscola" },
        { text: "Cnpj Uex", value: "cnpjUex" },
        { text: "Nome Escola", value: "nomeEscola" },
        { text: "Valor Repasse", value: "valorRepasse" },
        { text: "Custeio", value: "custeio" },
        { text: "Capital", value: "capital" },
        { text: "Ano", value: "ano"},
        { text: "Ações", value: "actions", align: "center", sortable: false },
      ],
      data: [],
    },
    cleanRepasse: {
      repasseId: 0,
      valorRepasse: 0,
      custeio: 0,
      capital: 0,
      cnpjEscola: "",
      cnpjUex: "",
      nomeEscola: "",
      codigoMec: 0,
      nomeDeUsuario: "",
      ano: "",
    },
    defaultRepasse: {
      repasseId: 0,
      valorRepasse: 0,
      custeio: 0,
      capital: 0,
      cnpjEscola: "",
      cnpjUex: "",
      nomeEscola: "",
      codigoMec: 0,
      nomeDeUsuario: "",
      ano: "",
    },
  }),
  methods: {
    showDialogExcel() {
      this.files = null;
      this.dialogExcel = true
    },
    editItem(item) {
      this.editedIndex = this.grid.data.indexOf(item);
      this.indexRepasse = this.editedIndex;
      this.defaultRepasse = Object.assign({}, item);
      this.showDialog();
    },
    showDialog() {
      this.dialog = true;
    },
    closeDialogExcel() {
      this.files = null;
      this.dialogExcel = false;
      this.getRepasses();
    },
    getFormDataExcel() {
      this.defaultRepasse.nomeDeUsuario = this.user.username;
      let data = new FormData();
      data.append("anexos", this.files);
      data.append("nomeDeUsuario", this.defaultRepasse.nomeDeUsuario);
      return data;
    },
    getRepasses() {
      this.grid.loading = true;
      this.$api()
        .get(configurations.api.admin.repasses.getRepassesUri)
        .then(({ data }) => {
          this.grid.data = data.repasses;
        })
        .catch(({ response }) => {
          if (response && response.descricao) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning(
              "Não conseguimos consultar a lista de repasses."
            );
          } 
        })
        .then(() => {
          this.grid.loading = false;
          this.hidePageLoading();
        });
    },
    handleItemExcel() {
      this.showPageLoading();
      const dataSend = this.getFormDataExcel();
      this.$api()
        .post(
          configurations.api.admin.repasses.getRegisterFonteDeDadosRepassesUri,
          dataSend
        )
        .then(({ data }) => {
          if (data && data.sucesso) {
            this.notifySuccess("Repasses importados com sucesso.");
          } else {
            this.notifyWarning("Não conseguimos importar o excel informado.");
          }
        })
        .catch(({ response }) => {
          if (response && response.descricao) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos importar o excel informado.");
          }
        })
        .then(() => {
          this.closeDialogExcel();
          this.hidePageLoading();
        });
    },
    handleItem() {
      this.defaultRepasse.nomeDeUsuario = this.user.username;
      this.$refs.form.validate();
      if (this.isFormValid) {
        if (this.editedIndex > -1) {
          this.$api()
            .put(configurations.api.admin.repasses.getUpdateRepasseUri, this.defaultRepasse)
            .then(({ data }) => {
              if (data && data.repasse) {
                Object.assign(this.grid.data[this.indexRepasse], data.repasse);
                this.notifySuccess("Repasse atualizado com sucesso.");
                this.close();
              }
            })
            .catch(({ response }) => {
              if (response && response.data) {
                this.notifyWarning(response.data.mensagem);
              } else {
                this.notifyWarning("Não conseguimos atualizar o repasse.");
              }
            });
        } else {
          this.addRepasse();  
        }
      }
    },
    addRepasse() {
      this.showPageLoading();
      delete this.defaultRepasse.repasseId;
      this.defaultRepasse.nomeDeUsuario = this.user.username;
      this.$api()
        .post(configurations.api.admin.repasses.getRegisterRepasseUri, this.defaultRepasse)
        .then(({ data }) => {
          if (data && data.repasse) {
            this.grid.data.unshift(data.repasse);
            this.notifySuccess("Repasse cadastrado com sucesso.");
            this.close();
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning("Não conseguimos cadastrar o repasse.");
          }
        })
        .then(() => {
          this.getRepasses();
        });
    },
    deletarRepasse(item){
      var result = confirm("Tem certeza de que deseja excluir este repasse?");
      if(result){
        this.$api()
          .post(configurations.api.admin.repasses.getDeleteRepasseUri, {
            repasseId: item.repasseId,
            nomeDeUsuario: this.user.username,
          })
          .then(({ data }) => {
            if (data && data.sucesso) {
              this.grid.data.splice(this.indexRepasse, 1);
              this.notifySuccess("Repasse deletado com sucesso.");
            }
          })
          .catch(({ response }) => {
            if (response && response.data) {
              this.notifyWarning(response.data.mensagem);
            } else {
              this.notifyWarning("Não conseguimos deletar o repasse.");
            }
          });
      }
    },
    closeExcel() {
      this.files = null;
      this.dialogExcel = false;
    },
    close() {
      this.dialog = false;
      this.defaultRepasse = Object.assign({}, this.cleanRepasse);
      this.editedIndex = -1;
    },
    filter() {
      if (this.grid.filters.nomeEscola ||
          this.grid.filters.filters ||
          this.grid.filters.codigoMec ||
          this.grid.filters.cnpjUex ||
          this.grid.filters.cnpjEscola ||
          this.grid.filters.ano) {
        // Filtrar
        this.grid.filtered = true;
      } else {
        // Limpar filtro
        this.grid.filtered = false;
      }
      this.mobileFilter = false;
    },
    verificarMenuDoUsuario() {
      if (this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`].filter(m => m.url === "/admin/repasses").length === 0) {
        this.$router.push({ path: "/login" });
      }
    }
  },
  computed: {
    ...postLoginGetters([USER]),
    filteredItems() {
      return this.grid.data.filter((i) => {
        return (
          i.nomeEscola
            .toLowerCase()
            .includes(this.grid.filters.nomeEscola.toLowerCase()) &&
          i.cnpjEscola.includes(this.grid.filters.cnpjEscola.toLowerCase()) &&
          i.codigoMec.toString().includes(this.grid.filters.codigoMec.toString()) &&
          i.cnpjUex.includes(this.grid.filters.cnpjUex) &&
          i.ano.includes(this.grid.filters.ano)
        );
      });
    },
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar repasse" : "Editar repasse";
    },
    rules() {
      return {
        nomeEscola: [
          (value) => Validations.required(value),
          (value) => Validations.min(value, 10),
        ],
        codigoMec: [
          (value) => Validations.required(value),
          (value) => Validations.onlyNumber(value),
          (value) => Validations.min(value, 3),
        ],
        cnpjEscola: [
          (value) => Validations.required(value),
          (value) => Validations.onlyNumber(value),
          (value) => Validations.min(value, 14),
          (value) => Validations.max(value, 14),
        ],
        cnpjUex: [
          (value) => Validations.required(value),
          (value) => Validations.onlyNumber(value),
          (value) => Validations.min(value, 14),
          (value) => Validations.max(value, 14),
        ],
        ano: [
          (value) => Validations.required(value),
          (value) => Validations.onlyNumber(value),
          (value) => Validations.min(value, 4),
          (value) => Validations.max(value, 4),
          
        ]
      };
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  .col-email {
    max-width: 200px;
  }

  .col-address {
    max-width: 200px;
  }
}

.shorten {
  max-width: calc(100% - 260px);
}

.hidden {
  visibility: hidden;
  display: none;
}
</style>
